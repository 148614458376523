import { signal } from "@preact/signals-react";
import { SITUACAO } from "../constantes";

export const menuSelecionado = signal(SITUACAO.PACIENTE);
export const telaProtocolo = signal(true);
export const dadosExame = signal(null);
export const exameSelecionado = signal(null);
export const desktop = signal(false);
export const podeExibirGaleria = signal(false);
export const carregando = signal({
    open: false,
    descricao: 'Carregando'
});