import React, { useContext } from 'react';
import { SttCarimboTempo, SttExpansionPanel, SttTable, SttTableBody, SttTableCell, SttTableHead, SttTableRow, SttTranslateHook, SttHidden, SttText } from '@stt-componentes/core';
import he from 'he';
import Alert from '@material-ui/lab/Alert';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { dadosExame, exameSelecionado } from '../../../signals';
import { signal } from '@preact/signals-react';

const Resultado = () => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const laudo = useSignal(null);
    const erroLaudo = signal(null);
    const carimboTempo = signal(null);
    const urlCarimbo = signal(null);

    useSignalEffect(() => {
        laudo.value = dadosExame.value[exameSelecionado.value].laudo;

        if (laudo.value.conteudoLaudo) {
            let textoLaudo = he.decode(laudo.value.conteudoLaudo);
            laudo.value.conteudoLaudo = textoLaudo;
        }

        if (laudo.value.erro) {
            erroLaudo.value = laudo.mensagem;
        }

        carimboTempo.value = laudo.value.carimboTempo;

        if (carimboTempo.value && carimboTempo.value.id && carimboTempo.value.id_laudo) {
            urlCarimbo.value = `${global.gConfig.url_base_exames}laudo/carimbo-tempo?id-carimbo=${carimboTempo.value.id}&id-laudo=${carimboTempo.value.id_laudo}`;
        }

        let descritoresCid10 = laudo.value.descritoresCid10;

        if (descritoresCid10) {
            descritoresCid10.forEach(element => {
                element.descricao_categoria_formatada = `${element.codigo_cid10_categoria} - ${element.descricao_categoria}`;
                if (element.codigo_cid10_subcategoria) {
                    element.descricao_subcategoria_formatada = `${element.codigo_cid10_subcategoria} - ${element.descricao_subcategoria}`;
                }
            });

            laudo.value.descritoresCid10 = descritoresCid10;
        }
    });

    return (
        <>
            {
                urlCarimbo.value &&
                <SttExpansionPanel title={strings.carimboTempo}
                    children={
                        <SttCarimboTempo
                            text={carimboTempo.value.hash_carimbo}
                            date={carimboTempo.value.data_hora_formatada}
                            url={urlCarimbo.value}
                        />
                    }
                />
            }

            {
                laudo.value && laudo.value.conteudoLaudo &&
                <SttExpansionPanel title={strings.dadosResultados}
                    children={
                        <div dangerouslySetInnerHTML={{ __html: laudo.value.conteudoLaudo }} />
                    }
                />
            }

            {
                !laudo.value && !erroLaudo.value &&
                <SttExpansionPanel title={strings.dadosResultados}
                    children={
                        <Alert severity="info">{strings.exameSemLaudo}</Alert>
                    }
                />
            }

            {
                erroLaudo.value &&
                <SttExpansionPanel title={strings.dadosResultados}
                    children={
                        <Alert severity="error">{strings.exameErroLaudo}</Alert>
                    }
                />
            }

            {
                laudo.value?.descritoresSBC?.length > 0 &&
                <SttExpansionPanel title={strings.descritoresSBC}
                    children={
                        <>
                            {
                                <SttTable>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="20%">{strings.sigla}</SttTableCell>
                                                <SttTableCell width="35%">{strings.descritor}</SttTableCell>
                                                <SttTableCell width="45%">{strings.definicaoSBC}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            laudo.value.descritoresSBC.map((row, index) => (
                                                <SttTableRow key={index}>
                                                    <SttHidden xsDown>
                                                        <SttTableCell>{row.sigla}</SttTableCell>
                                                        <SttTableCell>{row.descritor}</SttTableCell>
                                                        <SttTableCell>{row.definicao_sbc}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden smUp>
                                                        <SttTableCell width="96%">
                                                            <SttText size="small">
                                                                <b>{strings.sigla}:</b> {row.sigla}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.descritor}:</b> {row.descritor}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.definicaoSBC}:</b> {row.definicao_sbc}
                                                            </SttText>
                                                        </SttTableCell>
                                                    </SttHidden>
                                                </SttTableRow>
                                            ))
                                        }
                                    </SttTableBody>
                                </SttTable>
                            }
                        </>
                    } />
            }

            {
                laudo.value?.descritoresCid10?.length > 0 &&
                <SttExpansionPanel title={strings.cid10}
                    children={
                        <>
                            {
                                <SttTable>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="20%">{strings.codigo}</SttTableCell>
                                                <SttTableCell width="35%">{strings.categoria}</SttTableCell>
                                                <SttTableCell width="45%">{strings.subcategoria}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            laudo.value.descritoresCid10.map((row, index) => (
                                                <SttTableRow key={index}>
                                                    <SttHidden xsDown>
                                                        <SttTableCell>{row.codigo_cid10_subcategoria ? row.codigo_cid10_subcategoria : row.codigo_cid10_categoria}</SttTableCell>
                                                        <SttTableCell>{row.descricao_categoria}</SttTableCell>
                                                        <SttTableCell>{row.descricao_subcategoria}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden smUp>
                                                        <SttTableCell width="96%">
                                                            <SttText size="small">
                                                                <b>{strings.codigo}:</b> {row.codigo_cid10_subcategoria ? row.codigo_cid10_subcategoria : row.codigo_cid10_categoria}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.categoria}:</b> {row.descricao_categoria}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.subcategoria}:</b> {row.descricao_subcategoria}
                                                            </SttText>
                                                        </SttTableCell>
                                                    </SttHidden>
                                                </SttTableRow>
                                            ))
                                        }
                                    </SttTableBody>
                                </SttTable>
                            }
                        </>
                    } />
            }

            {
                laudo.value?.descritoresDecs?.length > 0 &&
                <SttExpansionPanel title={strings.descritores}
                    children={
                        <>
                            {
                                <SttTable>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="30%">{strings.sigla}</SttTableCell>
                                                <SttTableCell width="35%">{strings.descritor}</SttTableCell>
                                                <SttTableCell width="45%">{strings.descritorAntecessor}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            laudo.value.descritoresDecs.map((row, index) => (
                                                <SttTableRow key={index}>
                                                    <SttHidden xsDown>
                                                        <SttTableCell>{row.id}</SttTableCell>
                                                        <SttTableCell>{row.descritor}</SttTableCell>
                                                        <SttTableCell>{row.descritor_antecessor}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden smUp>
                                                        <SttTableCell width="96%">
                                                            <SttText size="small">
                                                                <b>{strings.sigla}:</b> {row.id}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.descritor}:</b> {row.descritor}
                                                            </SttText>
                                                            <SttText size="small">
                                                                <b>{strings.descritorAntecessor}:</b> {row.descritor_antecessor}
                                                            </SttText>
                                                        </SttTableCell>
                                                    </SttHidden>
                                                </SttTableRow>
                                            ))
                                        }
                                    </SttTableBody>
                                </SttTable>
                            }
                        </>
                    } />
            }
        </>
    );
};

export default Resultado;