import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttTranslateHook,
    SttNotification,
    SttAlertTitle,
    SttLink,
    SttModal,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import FileType from 'file-type';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

const ListaAnexos = ({ anexos }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const imagem = useSignal(null);
    const pdf = useSignal(null);

    const baixarAnexo = (anexo) => {
        axios
            .get(anexo.url, { responseType: 'blob' })
            .then((response) => {
                handleFile(response.data, anexo.nome_arquivo);
            })
            .catch(err => console.log(err));
    };

    const handleFile = async (file, fileName) => {
        const fileSpecs = await FileType.fromBuffer(Buffer.from(await file.arrayBuffer()));
        switch (fileSpecs.mime) {
            case 'application/pdf':
                pdf.value = file;
                break;
        
            case 'image/jpeg':
            case 'image/png':
                imagem.value = file;
                break;
            default:
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                break;
        }
    }

    const fecharVisualizacao = () => {
        pdf.value = null;
        imagem.value = null;
    }

    return (
        <React.Fragment>
            <SttNotification severity="success" icon={false}>
                <SttAlertTitle>{strings.anexos}</SttAlertTitle>
                <div className={classes.notification}>
                    {
                        anexos.map((anexo) => {
                            return <SttLink 
                                key={anexo.nome_arquivo} 
                                style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                                onClick={() => baixarAnexo(anexo)}
                                target={`_blank`}
                            >
                                {anexo.nome_arquivo}
                            </SttLink>
                        })
                    }
                </div>
            </SttNotification>
            {
                (imagem.value || pdf.value) &&
                <SttModal
                    title={strings.anexo}
                    open={imagem.value || pdf.value}
                    outModalClose={fecharVisualizacao}
                    iconClose={fecharVisualizacao}
                    maxWidth="md"
                    fullWidth={true}
                    children={
                        <>
                            {
                                imagem.value ? 
                                    <img src={URL.createObjectURL(imagem.value)} width="100%" /> : 
                                    <object data={URL.createObjectURL(new Blob([pdf.value], { type: 'application/pdf' }))} type="application/pdf" width="100%" height={`${window.screen.height * 0.7}`}>
                                        <p>{strings.erro}</p>
                                    </object>

                            }
                        </>
                    }
                    footer={
                        <SttButton variant="outlined" color="primary" onClick={fecharVisualizacao}>
                            {strings.fechar}
                        </SttButton>
                    }
                />

            }
        </React.Fragment>
    );
};

export default ListaAnexos;