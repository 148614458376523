import React from 'react';
import TelaProtocolo from '../TelaProtocolo';
import TelaInterno from '../TelaInterno';
import { useLocation, useParams } from "react-router-dom";
import { carregando, telaProtocolo } from '../../signals';
import { useSignals } from '@preact/signals-react/runtime';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import alerta from '../../signals/alerta';

const TelaGeral = () => {
    useSignals();
    const { hash } = useLocation();

    let { protocoloUrl } = useParams();
    if (protocoloUrl && protocoloUrl.trim() != '' && hash && hash.trim() != '') {
        protocoloUrl += hash;
    }

    return (
        <>
            {
                telaProtocolo.value ?
                    <TelaProtocolo protocoloUrl={protocoloUrl} /> :
                    <TelaInterno />
            }

            <SttAlerta {...alerta.value} />
            <SttLoading
                open={carregando.value.open}
                text={carregando.value.descricao}
            />
        </>
    );
};

export default TelaGeral;
