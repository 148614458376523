import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const initializeApp = (config) => {
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    
    root.render(<App currentLanguage={'meusExames'} />);
}

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split("/");

        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(
                `${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`,
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then((response) => response.json());
        }
    }
    return config;
}

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then(response => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then(response => response.json())
])
    .then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch(error => { console.error("Erro ao buscar os arquivos de configurações do frontend", error); });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
