const stringsComuns = {
    acessar: 'Acessar',
    achados: 'Achados',
    adicionar: 'Adicionar',
    administrativo: 'Administrativo',
    aguarde: 'Aguarde...',
    aguardeSalaVideo: 'Aguarde enquanto configuramos a sala de atendimento...',
    ajudaCustoComPernoite: 'Número de ajuda de custo com pernoite',
    ajudaCustoSemPernoite: 'Número de ajuda de custo sem pernoite',
    alterar: 'Alterar',
    alterarMeusDados: 'Alterar meus dados',
    altura: 'Altura (cm)',
    alturaMenorIgualTrezentos: 'Deve ser menor ou igual a 300',
    ambos: 'Ambos',
    anexo: 'Anexo',
    anexos: 'Anexos',
    anoCompetencia: 'Ano competência',
    anoConterQuatroCaracteres: 'O ano deve conter exatamente 4 caractéres',
    anoMesCompetencia: 'Ano/mês competência',
    anos: 'Anos',
    anosFuma: 'Anos que fuma',
    antecedentes: 'Antecedentes',
    anterior: 'Anterior',
    apenasInteirosPositivos: 'Apenas números inteiros positivos são aceitos',
    aprovar: 'Aprovar',
    arquivo: 'Arquivo',
    assinar: 'Assinar',
    assunto: 'Assunto',
    atencao: 'Atenção',
    atipico: 'Atípico',
    ativa: 'Ativa',
    atividade: 'Atividade',
    ativo: 'Ativo',
    autenticacaoSegura: 'Autenticação segura',
    autorizar: 'Autorizar',
    avaliar: 'Avaliar',
    avisoImcCrianca1: 'Para classificação do estado nutricional de pacientes abaixo de 19 anos, consulte os protocolos de classificação do estado nutricional do SISVAN, disponíveis no site do Ministério da Saúde.',
    avisoImcCrianca2: 'para acessar os protocolos.',
    avisoImcCrianca3: 'Equipe Núcleo Telessaúde UFSC.',
    avisoMensagemBloqueia: 'Essa mensagem bloqueia o uso do sistema para o seu usuário. Por favor, entre em contato com a administração do sistema para mais informações.',
    bairro: 'Bairro',
    buscar: 'Buscar',
    buscarFuncionario: 'Buscar funcionário',
    buscarPaciente: 'Buscar paciente',
    buscarProcedimentoSigtap: 'Buscar procedimentos SIGTAP',
    buscarSolicitante: 'Buscar solicitante',
    cadastrar: 'Cadastrar',
    cadastrarPaciente: 'Cadastrar paciente',
    campoInvalido: 'Inválido',
    campoObrigatorio: 'Campo obrigatório',
    cancelar: 'Cancelar',
    capitulo: 'Capítulo',
    carimboTempo: 'Carimbo do tempo',
    carregando: 'Carregando',
    carregandoAnexo: 'Carregando anexo...',
    carregandoGaleria: 'Carregando galeria de imagens',
    carregandoImagens: 'Carregando imagens',
    carregandoProcessos: 'Carregando Processos...',
    cartaoSus: 'Cartão SUS',
    categoria: 'Categoria',
    cbo: 'CBO',
    cboFamilia: 'CBO família',
    cboOcupacao: 'CBO ocupação',
    cep: 'CEP',
    ciap2: 'CIAP-2',
    ciap2Adicionado: 'CIAP-2 adicionado',
    ciap2AdicionadoAnteriormente: 'Este CIAP-2 já foi adicionado anteriormente',
    cid10: 'CID-10',
    cid10Adicionado: 'CID-10 adicionado',
    cid10AdicionadoAnteriormente: 'Este CID-10 já foi adicionado anteriormente',
    cid10Principal: 'CID-10 Principal',
    cid10Secundario: 'CID-10 Secundário',
    cidade: 'Município',
    cidadeDestino: 'Município de destino',
    cidadeOrigem: 'Município de origem',
    cidadeProcedencia: 'Município de procedência',
    cidadeUf: 'Município/UF',
    cigarrosDia: 'Cigarros por dia',
    classificacao: 'Classificação',
    classificacaoNutricional: 'Classificação do estado nutricional',
    cliqueAqui: 'CLIQUE AQUI',
    cm: 'cm(s)',
    cnes: 'CNES',
    cnpjInvalido: 'CNPJ inválido',
    cns: 'CNS',
    cnsInvalido: 'CNS inválido',
    codigo: 'Código',
    comLaudo: 'Laudado',
    comorbidades: 'Comorbidades',
    complemento: 'Complemento',
    comprovanteComparecimento: 'Comprovante de comparecimento',
    comprovantesComparecimento: 'Comprovantes de comparecimento',
    conclusao: 'Conclusão',
    concordo: 'Concordo',
    confirmacao: 'Confirmação',
    confirmacaoLeitura: 'Confirmação de leitura',
    confirmar: 'Confirmar',
    confirmarExclusao: 'Você confirma a exclusão desta equipe de saúde?',
    confirmarInvalidarExame: 'Você deseja realmente invalidar este exame?',
    confirmarLeitura: 'Confirmar leitura',
    confirmarPriorizarExame: 'Você deseja realmente priorizar este exame?',
    confirmarTrocarRedeExame: 'Você deseja realmente trocar a rede deste(s) exame(s)?',
    conselhoTrabalho: 'Conselho de trabalho',
    contato: 'Contato',
    continuar: 'Continuar',
    copiar: 'Copiar',
    cpf: 'CPF',
    cpfInvalido: 'CPF inválido',
    crm: 'CRM',
    crmRms: 'CRM/RMS',
    dadosAgendamento: 'Dados do agendamento',
    dadosContato: 'Dados de contato',
    dadosExame: 'Dados do exame',
    dadosGerais: 'Dados gerais',
    dadosPaciente: 'Dados do paciente',
    dadosProfissionais: 'Dados profissionais',
    data: 'Data',
    dataAte: 'Até',
    dataAtendimento: 'Data de atendimento',
    dataCriacao: 'Data da criação',
    dataDe: 'De',
    dataExame: 'Data do exame',
    dataFinal: 'Data final',
    dataFinalAnteriorDataInicial: 'Data final deve ser posterior a data inicial',
    dataFinalExame: 'Data final do exame',
    dataFinalInvalidacao: 'Data final da invalidação',
    dataFinalLaudo: 'Data final do laudo',
    dataFinalSolicitacao: 'Data final da solicitação',
    dataHora: 'Data/hora',
    dataInicial: 'Data inicial',
    dataInicialExame: 'Data inicial do exame',
    dataInicialInvalidacao: 'Data inicial da invalidação',
    dataInicialLaudo: 'Data inicial do laudo',
    dataInicialSolicitacao: 'Data inicial da solicitação',
    dataInvalida: 'Data inválida',
    dataNascimento: 'Data de nascimento',
    dataNascimentoMinima: 'A data de nascimento não pode ser anterior a 01/01/1900',
    dataPreenchimentoLaudoMedico: 'Data de preenchimento do laudo médico',
    dataProrrogada: 'Data prorrogada',
    dataSolicitacao: 'Data da solicitação',
    de: 'de',
    decs: 'DeCS',
    decsAdicionado: 'DeCS adicionado',
    decsAdicionadoAnteriormente: 'Este DeCS já foi adicionado anteriormente',
    desconhecido: 'Desconhecido',
    descricao: 'Descrição',
    descricaoDecs: 'O DeCS é um vocabulário dinâmico totalizando 30.369 descritores, sendo destes 25.671 do MeSH e 4698 exclusivamente do DeCS. Existem 2001 códigos hierárquicos de categorias DeCS a 1458 descritores MeSH.',
    descricaoEstudo: 'Descrição do estudo',
    descritor: 'Descritor',
    descritorAntecessor: 'Descritor antecessor',
    descritores: 'Descritores',
    descritoresSBC: 'Descritores SBC',
    detalhesSolicitacao: 'Detalhes da solicitação',
    devolver: 'Devolver',
    dezMb: '10 Mb',
    digitador: 'Digitador',
    direcionar: 'Direcionar',
    direcionarExameLaudo: 'Direcionar exame para laudo',
    discordo: 'Discordo',
    download: 'Download',
    duracao: 'Duração',
    editar: 'Editar',
    email: 'E-mail',
    emailDuplicado: 'O email inserido já existe. Por favor, informe outro e-mail para continuar.',
    emailInvalido: 'E-mail inválido',
    emailObrigatorio: 'E-mail obrigatório',
    bemVindo: 'Bem-vindo',
    encaminhar: 'Encaminhar',
    encaminharRegulador: 'Encaminhar ao regulador',
    enviar: 'Enviar',
    enviarExame: 'Enviar exame',
    enviarImagens: 'Enviar imagens',
    envioImagens: 'Envio de imagens',
    equipamento: 'Equipamento',
    equipeDuplicada: 'Essa equipe já foi adicionada',
    equipeSaude: 'Equipe de Saúde',
    equipeSaudeFamilia: 'Equipe de Saúde da Família',
    erro: 'Erro',
    erroCarregamentoGaleria: 'Ocorreu um erro no carregamento da galeria. Por favor, contacte o administrador.',
    erroConectarBBB: 'Erro ao conectar na sala de áudio e vídeo. Deseja tentar novamente?',
    erroConteudoHtml: 'O campo deve ter pelo menos 10 caracteres válidos',
    erroDataFutura: 'Data não pode ser futura',
    erroDataMenorDataInicial: 'Data deve ser posterior a data inicial',
    erroDataMenorDataInicio: 'Data deve ser posterior a data "De"',
    erroDataObrigatoria: 'A data é obrigatória.',
    erroDataPassada: 'A data não pode ser passada.',
    erroDownloaEstudoDicom: 'Download do estudo dicom indisponível. Tente novamente mais tarde.',
    erroEnviarAnexo: 'Não foi possível enviar esse anexo. Tente novamente mais tarde.',
    erroFaltaPermissao: 'O seu usuário não tem permissão para acessar essa funcionalidade no momento.',
    erroGenerico: 'Ocorreu um erro desconhecido. Entre em contato com os administradores do sistema.',
    erroGerarProtocoloEnvioExame: 'O exame foi registrado, mas não foi possível gerar o protocolo. Para ter acesso ao protocolo, acesse a tela de exames.',
    erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
    erroIgnorarExame: 'Não foi possível ignorar o exame. Se o mesmo exame for carregado, tente ignorá-lo novamente. Se o erro persistir, entre em contato com o suporte.',
    erroImpressaoExame: 'Ocorreu um erro na impressão do exame. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    erroInvalidarExame: 'Não foi possível invalidar o exame. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    erroMaxCaracteres: (maxCaracteres, nomeCampo) => `O campo ${nomeCampo} deve ter no máximo ${maxCaracteres} caractéres.`,
    erroPriorizarExame: 'Não foi possível priorizar o exame. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    erroTamanhoArquivo: 'O arquivo não pode ter mais de % Mb',
    erroTamanhoArquivo2Mb: 'Arquivo não pode ter mais de 2 Mb',
    erroTamanhoArquivo10Mb: 'Arquivo não pode ter mais de 10 Mb',
    erroTamanhoArquivo50Mb: 'Arquivo não pode ter mais de 50 MB',
    erroTamanhoArquivo500Mb: 'Arquivo não pode ter mais de 500 Mb',
    erroTamanhoCombinadoArquivosMaiorQue50MB: 'O tamanho combinado dos arquivos anexos não pode ser maior que 50 MB',
    erroTrocarRedeExame: 'Não foi possível trocar a rede do exame. Tente novamente. Se o erro persistir, entre em contato com o suporte.',
    espacosNaoPermitidos: 'Espaços não são permitidos',
    especificacao: 'Especificação',
    especificacaoTempo: 'Especificação do tempo',
    estado: 'Estado',
    estadoConselhoTrabalho: 'Estado do conselho de trabalho',
    etilismo: 'Etilismo',
    exame: 'Exame',
    exameDirecionado: 'Exame direcionado',
    exameEnviadoSucesso: 'Exame enviado com sucesso.',
    exameErroLaudo: 'Ocorreu um erro ao carregar o laudo. Tente novamente mais tarde.',
    exameInvalidado: 'Exame invalidado',
    exameMaisAntigoSemLaudo: 'Data do exame mais antigo sem laudo',
    examePriorizado: 'Exame priorizado',
    exameRegistrado: 'Exame(s) registrado(s) com sucesso',
    exames: 'Exames',
    examesComLaudo: 'Exames laudados',
    exameSelecionado: 'Exame selecionado',
    exameSemLaudo: 'Não há laudo disponível para esse exame.',
    examesInvalidos: 'Exames inválidos',
    examesSelecionados: 'Exames selecionados',
    examesValidos: 'Exames válidos',
    excluir: 'Excluir',
    fatorRh: 'Fator Rh',
    fechar: 'Fechar',
    fecharJanelaDestaque: 'Fechar janela de destaque',
    feminino: 'Feminino',
    finalizar: 'Finalizar',
    finalizarPreenchimentoUltimoAnexo: 'Finalize o preenchimento do último anexo antes de adicionar um novo',
    formatoArquivoInvalido: 'Formato de arquivo inválido.',
    formatosArquivo: 'Arquivo (.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx)',
    formatosInput: {
        hhmm: 'HH:MM',
        validacaoHhMm: 'Deve ser especificado hora(s) e minuto(s)'
    },
    frequencia: 'Frequência',
    funcionario: 'Funcionário',
    funcionarioDigitador: 'Funcionário digitador',
    funcionarioExecutante: 'Funcionário executante',
    funcionarioRegulador: 'Funcionário regulador',
    funcionarios: 'Funcionários',
    genero: 'Gênero',
    gerandoDocumento: 'Gerando documento',
    gerandoNumeroProtocolo: 'Gerando protocolo',
    gerandoTCLE: 'Gerando Termo de Consentimento Livre e Esclarecido',
    gerarProtocolos: 'Gerar protocolos',
    gerarResumo: 'Gerar resumo',
    grupoSanguineo: 'Grupo sanguíneo',
    historico: 'Histórico',
    hora: 'Hora',
    horaInvalida: 'A combinação de horas e minutos informada é inválida',
    horas: 'hora(s)',
    idade: 'Idade',
    idadeMaiorDezoito: 'O funcionário deve ter mais do que 18 anos',
    identificador: 'Identificador',
    ignorandoExame: 'Ignorando exame',
    ignorar: 'Ignorar',
    imagem: 'Imagem',
    imagens: 'Imagens',
    imagensIndisponiveis: 'Algumas imagens não estão disponíveis para visualização. Para visualizar as imagens, entre em contato com o suporte.',
    imc: 'IMC',
    imprimir: 'Imprimir',
    imprimirProtocolo: 'Imprimir protocolo',
    imprimirTermo: 'Termo de autorização',
    inativa: 'Inativa',
    inativar: 'Inativar',
    inativo: 'Inativo',
    indicacaoClinica: 'Indicação clínica',
    infeccoes: 'Infecções',
    infoImc: 'Informações sobre o IMC',
    informacao: 'Informação',
    informacoesExame: 'Informações do exame',
    informeApacContinuidade: 'Detectamos que já existem processos de mesmo procedimento e unidade executora para este paciente. Você poderá marcar esta solicitação como do tipo continuidade, se desejar',
    informeApacPadrao: 'Detectamos que esta é a primeira solicitação do paciente para este procedimento e unidade executora. Esta solicitação foi marcada como sendo do tipo padrão',
    informeNomeCompleto: 'Informe o nome completo',
    inicio: 'Início',
    instituicao: 'Instituição',
    instituicaoDuplicada: 'Essa instituição já foi adicionada',
    instituicaoExecutante: 'Instituição executante',
    instituicaoObrigatoria: 'É obrigatório a adição de pelo menos uma instituição de vínculo.',
    instituicaoResponsavel: 'Instituição responsável',
    instituicaoSolicitante: 'Instituição solicitante',
    instituicoes: 'Instituições',
    intensidade: 'Intensidade',
    interestadual: 'Interestadual',
    internacao: 'Internação',
    intraestadual: 'Intraestadual',
    invalidandoExame: 'Invalidando exame',
    invalidar: 'Invalidar',
    invalido: 'Inválido',
    judicial: 'Judicial',
    justificativa: 'Justificativa',
    justificativaObrigatoria: 'A justificativa é obrigatória.',
    justificativaTransporteRecomendado: 'Justificativa do transporte recomendável',
    largura: 'Largura',
    laudo: 'Laudo',
    laudoAguardandoComplemento: 'Complemento',
    laudoEmissao: 'Emissão',
    laudosEmitidos: 'Laudos emitidos',
    laudosGraves: 'Laudos graves',
    laudosImportantes: 'Laudos importantes',
    laudoTemporario: 'Temporário',
    liCiente: 'Li e estou ciente.',
    limpar: 'Limpar',
    linhasPorPagina: 'Linhas por página',
    localizacao: 'Localização',
    localPreenchimentoLaudoMedico: 'Local de preenchimento do laudo médico',
    login: 'Login',
    loginDuplicado: 'O login inserido já existe. Por favor, informe outro para continuar.',
    logout: 'Logout',
    logradouro: 'Logradouro',
    maiorZero: 'Deve ser maior que 0',
    maisMedicos: 'Mais médicos',
    maisMedicosResidencia: 'Mais médicos / PROVAB / Residência',
    maisSimbolo: '+',
    masculino: 'Masculino',
    materiaisSuporte: 'Materiais de apoio',
    matutino: 'Matutino',
    maximo10Caracteres: 'Deve ter no máximo 10 caracteres',
    maximo30Caracteres: 'Deve ter no máximo 30 caracteres',
    maximo255Caracteres: 'Deve ter no máximo 255 caracteres',
    medicamento: 'Medicamento',
    medicoSolicitante: 'Médico solicitante',
    melhor: 'Melhor',
    menosSimbolo: '-',
    mensagemBoasVindas: 'Mensagem de boas-vindas',
    mensagemCancelarEnvioImagens: 'Deseja cancelar o envio das imagens?',
    mensagemConfirmacaoEnvioSolicitacao: 'Você confirma o envio da solicitação?',
    mensagemConfirmarEnvioExame: 'Deseja enviar as fotos das lesões agora?',
    mensagemConfirmarTermo_solicitacao: 'Deseja imprimir o TCLE? O mesmo documento será impresso ao término do envio das imagens.',
    mensagemEnviandoImagens: 'Enviando imagens',
    mensagemErroBuscaCid10Termo: 'Para pesquisas utilizando a opção "Termo completo" é obrigatório informar o termo a ser encontrado. Para pesquisas onde não se pretende informar o termo a ser encontrado deverá ser utilizada a opção "Termo parcial".',
    mensagemErroBuscaInstituicaoSemFiltros: 'Informe ao menos um filtro para realizar a pesquisa.',
    mensagemErroBuscaTermo: 'Para pesquisar é obrigatório informar o termo a ser encontrado.',
    mensagemErroGeral: 'Ocorreu um erro. Tente novamente mais tarde',
    mensagemPopupBloqueado: 'Por favor, permita que seu navegador abra pop-ups (geralmente, na barra de endereços do navegador) e repita a operação.',
    minimo1Caracter: 'Deve ter no mínimo 1 caracter',
    minTresLetras: 'A descrição deve ter ao menos 3 caracteres',
    minutos: 'minuto(s)',
    modalidade: 'Modalidade',
    modalidadeDuplicada: 'Essa modalidade já foi adicionada',
    modalidadeInstituicaoDuplicada: 'Essa combinação de modalidade e instituição já foi adicionada',
    modalidadeObrigatoria: 'É obrigatório a adição de pelo menos uma modalidade de vínculo.',
    modalidadeRedeDuplicada: 'Essa combinação de modalidade e rede já foi adicionada',
    modulo: 'Módulo',
    modulos: 'Módulos',
    motivo: 'Motivo',
    motivoEncaminhamento: 'Motivo do encaminhamento',
    movimentacao: 'Movimentação',
    movimentacoes: 'Movimentações',
    muitoPior: 'Muito pior',
    mumeroPositivo: 'Deve ser maior que 0',
    municipio: 'Município',
    municipios: 'Municípios',
    municipiosAdicinados: 'Municípios adicionados',
    nao: 'Não',
    naoAutorizado: 'Não autorizado',
    naoFoiPosisvelCarregarExamesInvalidos: 'Não foi possível carregar os dados de exames inválidos',
    naoFoiPossivelCarregarExamesComLaudo: 'Não foi possível carregar os dados de exames laudados',
    naoFoiPossivelCarregarLaudosEmitidos: 'Não foi possível carregar os dados de laudos emitidos',
    naoFoiPossivelCarregarSemLaudo: 'Não foi possível carregar os dados de exames sem laudo',
    naoFoiPossivelCarregarSemLaudo72h: 'Não foi possível carregar os dados de exames com laudo atrasado',
    naoFoiPossivelCarregarTotais: 'Não foi possível carregar os dados de produtividade',
    naoInformado: 'Não informado',
    naoRespondeu: 'Não respondeu',
    negar: 'Negar',
    nenhum: 'Nenhum',
    nenhumCiap2Adicionado: 'Nenhum CIAP-2 adicionado',
    nenhumCid10Adicionado: 'Nenhum CID-10 adicionado',
    nenhumDecsAdicionado: 'Nenhum DeCS adicionado',
    nenhumPacienteEncontradoCadastro: 'Nenhum paciente encontrado. Deseja cadastrar?',
    nenhumPerfilAdicionado: 'Nenhum perfil adicionado',
    nenhumRegistroEncontrado: 'Nenhum registro encontrado',
    nenhumResultado: 'Nenhum resultado para ser exibido',
    nenhumSigtapAdicionado: 'Nenhum SIGTAP adicionado',
    nome: 'Nome',
    nomeAbreviado: 'Nome abreviado',
    nomeCompleto: 'Nome completo',
    nomeFuncionario: 'Nome do funcionário',
    nomeInvalido: 'Nome inválido',
    nomeMae: 'Nome da mãe',
    nomePaciente: 'Nome do paciente',
    nomePacienteInvalido: 'Informe o nome completo (somente letras)',
    nomePacienteSomenteLetras: 'O nome do paciente só pode conter letras',
    nomeProcedimento: 'Nome do Procedimento',
    nomeSistema: 'Sistema Integrado Catarinense de Telemedicina e Telessaúde',
    nomeSolicitanteSomenteLetras: 'O nome do solicitante só pode conter letras',
    notasAtualizacao: 'Notas de atualização',
    notificacoes: 'Notificações',
    novaRede: 'Nova rede',
    numero: 'Número',
    numeroAcompanhantes: 'Número de acompanhantes',
    numeroAcompanhantesNegativo: 'O número de acompanhantes não pode ser negativo.',
    numeroAcompanhantesObrigatorio: 'O número de acompanhantes é obrigatório.',
    numeroCelularInvalido: 'Número de celular deve ter 9 dígitos',
    numeroCelularNoveDigitos: 'Número de celular deve ter 9 dígitos',
    numeroConselhoTrabalho: 'Número do conselho de trabalho',
    numeroConselhoTrabalhoDuplicado: 'Este número de conselho de trabalho já existe',
    numeroExame: 'Número do exame',
    numeroLaudo: 'Número do laudo',
    numeroPedido: 'Número do pedido',
    numeroPedidoCaracteres: 'O número do pedido não pode exceder 20 caracteres.',
    numeroTelefoneInvalido: 'Número de telefone deve ter 8 dígitos',
    numeroTelefoneOitoDigitos: 'Número de telefone deve ter 8 dígitos',
    numeroVagas: 'Número de vagas',
    nunca: 'Nunca',
    nuncaFumou: 'Nunca fumou',
    obesidade1: 'Obesidade Grau 1',
    obesidade2: 'Obesidade Grau 2',
    obesidade3: 'Obesidade Grau 3',
    observacao: 'Observação',
    observacaoTecnica: 'Observação técnica',
    observacoes: 'Observações',
    observacoesTecnico: 'Observações do técnico',
    ocupacao: 'Ocupação',
    substituirAnexos: 'CLIQUE AQUI para substituir as imagens atuais do exame',
    substituicaoAnexos: 'Substituição das imagens',
    mensagemImprimirImagensExame: 'Você gostaria de imprimir também as imagens deste exame?',
    ocupacaoNaoClassificada: 'Ocupação não classificada',
    ok: 'Ok',
    opcoes: 'Opções',
    operacaoSucesso: 'Operação realizada com sucesso.',
    ordenacao: 'Ordenação',
    origem: 'Origem',
    outras: 'Outras',
    outrasInformacoes: 'Outras informações',
    outro: 'Outro',
    outros: 'Outros',
    outrosExames: 'Outros Exames',
    paciente: 'Paciente',
    pacienteObrigatorio: 'O paciente é obrigatório',
    pacientes: 'Pacientes',
    galeriaAnexos: 'Galeria de anexos',
    paginaInicial: 'Página inicial',
    pais: 'País',
    palavraChave: 'Palavra-chave',
    participacao: 'Participação',
    participacoes: 'Participações',
    pendencias: 'Pendências',
    perfil: 'Perfil',
    entrar: 'Entrar',
    headingSttEbserh: 'STT - EBSERH',
    cpfPaciente: 'CPF do paciente',
    cnsPaciente: 'CNS do paciente',
    azureCpfIncompativelCadastro: 'O CPF vinculado a sua conta @ebserh.gov.br deve ser o mesmo de sua conta do STT.',
    perfilCidadeDuplicado: 'Essa combinação de perfil e município já foi adicionada',
    perfilDuplicado: 'Esse perfil já foi adicionado',
    perfilEstadoDuplicado: 'Essa combinação de perfil e estado já foi adicionada',
    perfilInstituicaoDuplicado: 'Essa combinação de perfil e instituição já foi adicionada',
    perfilRedeDuplicado: 'Essa combinação de perfil e rede já foi adicionada',
    perfis: 'Perfis',
    perfisStt1: 'Perfis do STT-1',
    perfisStt2: 'Perfis do STT-2',
    peso: 'Peso (kg)',
    pesoMenorIgualDuzentos: 'Deve ser menor ou igual a 200',
    pesoSaudavel: 'Peso Saudável',
    pesquisa: 'Pesquisa',
    pesquisandoDadosCadsus: 'Pesquisando dados do CADSUS',
    pesquisar: 'Pesquisar',
    pior: 'Pior',
    popupBloqueado: 'Pop-up bloqueado',
    preOperatorio: 'Pré-operatório',
    priorizado: 'Priorizado',
    priorizandoExame: 'Priorizando exame',
    priorizar: 'Priorizar',
    procedimento: 'Procedimento',
    procedimentoSolicitacdo: 'Procedimento solicitado',
    procurar: 'Procurar',
    profissao: 'Profissão',
    profissaoInvalida: 'Profissão inválida',
    profissionalSolicitante: 'Profissional solicitante',
    protocolo: 'Protocolo',
    protocoloCopiado: 'Protocolo copiado',
    provab: 'PROVAB',
    proximo: 'Próximo',
    prurido: 'Prurido',
    publicar: 'Publicar',
    quinhentosMb: '500 Mb',
    raca: 'Raça',
    racaEtnia: 'Raça/Etnia',
    rascunhoSalvoSucesso: 'Rascunho salvo com sucesso.',
    realizacao: 'Realização:',
    rede: 'Rede',
    redeAlterada: 'Rede alterada',
    redeTeleconsultoria: 'Rede de teleconsultoria',
    redeTelemedicina: 'Rede de telemedicina',
    regionalSaude: 'Gerência regional de saúde',
    rejeitar: 'Rejeitar',
    requisicao: 'Requisição',
    residencia: 'Residência',
    responsavel: 'Responsável',
    responsavelEnvio: 'Responsável pelo envio',
    retornarTelaPesquisa: 'Deseja retornar a tela de pesquisa? Dados não salvos serão perdidos.',
    rg: 'RG',
    rgInvalido: 'RG inválido',
    rqe: 'RQE',
    sair: 'Sair',
    sairSistema: 'Sair do sistema',
    salvando: 'Salvando',
    salvandoMensagemEspera: 'Salvando...',
    salvandoPaciente: 'Salvando paciente',
    salvandoSolicitacao: 'Salvando dados da solicitação',
    salvandoTermo: 'Salvando termo de consentimento...',
    salvar: 'Salvar',
    salvarRascunho: 'Salvar rascunho',
    selecao: 'Seleção',
    selecionar: 'Selecionar',
    selecionarArquivoValido: 'Ao menos um arquivo válido deve ser selecionado',
    semelhante: 'Semelhante',
    semImagens: 'Sem imagens',
    semImagensExibicao: 'Não há imagens disponíveis para exibição.',
    semInformacao: 'Sem informação',
    semLaudo: 'Sem laudo',
    semLaudo72h: 'Atrasado',
    senha: 'Senha',
    sequencial: 'Sequencial',
    series: 'Séries',
    servico: 'Serviço',
    servicos: 'Serviços',
    setor: 'Setor',
    setorDuplicado: 'Esse setor já foi adicionado',
    sexo: 'Gênero',
    sigla: 'Sigla',
    sigtap: 'SIGTAP',
    sigtapAdicionado: 'SIGTAP adicionado',
    sigtapAdicionadoAnteriormente: 'Este SIGTAP já foi adicionado anteriormente.',
    sim: 'Sim',
    situacao: 'Situação',
    situacaoCadastro: 'Situação do cadastro',
    situacaoExame: 'Situação do exame',
    situacaoLaudo: 'Situação do laudo',
    situacaoUsuario: 'Situação do usuário',
    sobrepeso: 'Sobrepeso',
    solicitacao: 'Solicitação',
    solicitacaoAlteradaSucesso: 'Solicitação alterada com sucesso.',
    solicitacaoEncaminhadaSucesso: 'Solicitação encaminhada com sucesso.',
    solicitacaoPassagemVinculo: 'Solicitação de passagem de vínculo',
    solicitacoesExame: 'Solicitações de exames',
    solicitante: 'Solicitante',
    solicitanteAdicionado: 'Solicitante adicionado',
    somenteLetrasPermitidas: 'Somente letras são permitidas',
    somenteLetrasSemAcentoPermitidas: 'Somente letras sem acentuação são permitidas',
    somenteNumeros: 'Somente números são permitidos',
    somenteNumerosPermitidos: 'Somente números são permitidos',
    subcategoria: 'Subcategoria',
    subnutricao: 'Subnutrição',
    sucesso: 'Sucesso',
    sucessoEquipeAdicionada: 'Equipe adicionada com sucesso',
    sucessoInstituicaoAdicionada: 'Instituição adicionada com sucesso',
    sucessoModalidadeAdicionada: 'Modalidade adicionada com sucesso',
    sucessoPerfilAdicionado: 'Perfil adicionado com sucesso',
    alertaTodosVinculosAdicionados : (perfil) => `O perfil "${perfil}" já foi adicionado para todas as instituições deste usuário.`,
    sucessoSetorAdicionado: 'Setor adicionado com sucesso',
    sumario: 'Sumário',
    tabagismo: 'Tabagismo',
    tamanhoCm: "Tamanho (em CM)",
    tecnico: 'Técnico',
    telefone: 'Telefone',
    telefoneCelular: 'Telefone celular',
    telefoneComercial: 'Telefone comercial',
    telefoneFixo: 'Telefone fixo',
    telefoneInvalido: 'Telefone inválido',
    telefoneResidencial: 'Telefone residencial',
    telemedicina: 'Telemedicina',
    telessaude: 'Telessaúde',
    tempo: 'Tempo',
    tempoDoenca: 'Tempo de doença',
    tentarNovamente: 'Tentar novamente',
    termino: 'Término',
    termoCompleto: 'Termo completo',
    termoConsentimento: 'Termo de Consentimento Livre e Esclarecido',
    termoEsclarecimento: 'Termo de esclarecimento',
    termoParcial: 'Termo parcial',
    textoInvalidarExame: 'Para invalidar um exame devido a problemas técnicos ou de aquisição, selecione um dos motivos abaixo.',
    textoInvalidarExameObs: 'Para invalidar um exame devido a problemas técnicos ou de aquisição, informe o motivo.',
    tipo: 'Tipo',
    tipoAnexo: 'Tipo de anexo',
    tipoAnexoObrigatorio: 'O tipo de anexo é obrigatório',
    tipoConselhoTrabalho: 'Tipo de conselho de trabalho',
    titulo: 'Título',
    tituloConfirmarEnvioExame: 'Deseja enviar as imagens?',
    tituloDecs: 'Consulta ao descritor Decs',
    tituloModalBuscarPaciente: 'Buscar paciente',
    tituloModuloApac: 'APAC',
    tituloModuloOxigenoterapia: 'Oxigenoterapia Domiciliar Adulto',
    tituloModuloOauth2: 'Bem-vindo ao STT',
    tituloModuloTfd: 'Tratamento fora do domicílio',
    todas: 'Todas',
    todasImagens: 'Todas as Imagens',
    todos: 'Todos',
    token_client_id: 'Client ID',
    token_client_secret: 'Client secret',
    token_url: 'URL token',
    transporteRecomendado: 'Transporte recomendado',
    trocandoRede: 'Trocando rede',
    trocarRede: 'Trocar rede',
    turma: 'Turma',
    uf: 'UF',
    ufDestino: 'UF de destino',
    ufOrigem: 'UF de origem',
    umTelefoneObrigatorio: 'Ao menos um número de telefone é obrigatório',
    url_base: 'URL base',
    urlInvalida: 'URL inválida',
    usuario: 'Usuário',
    usuarios: 'Usuários',
    validacaoString: {
        digitosOuNumeros: 'Este campo deve conter somente números e/ou dígitos'
    },
    valido: 'Válido',
    ver: 'Ver',
    versao_dcm4chee: 'Versão dcm4chee',
    vespertino: 'Vespertino',
    vinculo: 'Vínculo',
    vinculos: 'Vínculos',
    visualizacaoExames: 'Visualização de exames',
    visualizar: 'Visualizar',
    visualizarResumo: 'Visualizar resumo',
    voltar: 'Voltar',
    confirmarVincularAutenticacaoExterna: (origem, email) => {
        switch (origem) {
            case 'AZURE_AD':
                return email ? `Você tem certeza que deseja vincular a conta de e-mail ${email} ao seu login?` : 'Você tem certeza que deseja vincular esta conta Microsoft ao seu login?';
            case 'GOV_BR':
                return 'Você tem certeza que deseja vincular esta conta gov.br ao seu login?';
            default:
                return 'Você tem certeza que deseja vincular esta conta ao seu login?';
        }
    },
    confirmarDesvincularAutenticacaoExterna: (origem) => {
        switch (origem) {
            case 'AZURE_AD':
                return 'Você tem certeza que deseja desvincular sua conta Microsoft? A mesma poderá ser vinculada novamente.';
            case 'GOV_BR':
                return 'Você tem certeza que deseja desvincular sua conta gov.br? A mesma poderá ser vinculada novamente.';
            default:
                return 'Você tem certeza que deseja desvincular esta conta? A mesma poderá ser vinculada novamente.';
        }
    },
    autenticacaoObrigatoria: 'Esta autenticação é obrigatória e não pode ser desvinculada.',
    sucessoVincularAutenticacaoExterna: 'Sua conta foi vinculada a este login com sucesso.',
    sucessoDesvincularAutenticacaoExterna: 'Sua conta foi desvinculada a este login com sucesso.',
    erroAutenticacaoExternaJaAberta: 'A janela de autenticação externa já está aberta em outra aba do seu navegador.',
    vincular: (origem) => {
        switch (origem) {
            case 'AZURE_AD':
                return 'Vincular sua conta Microsoft Azure';
            case 'GOV_BR':
                return 'Vincular sua conta gov.br';
            default:
                return 'Vincular';
        }
    },
    desvincular: (origem) => {
        switch (origem) {
            case 'AZURE_AD':
                return 'Desvincular sua conta Microsoft Azure';
            case 'GOV_BR':
                return 'Vincular sua conta gov.br';
            default:
                return 'Desvincular';
        }
    },
};

module.exports = stringsComuns;
