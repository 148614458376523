import React, { useContext } from 'react';
import moment from 'moment';
import { SttExpansionPanel, SttTranslateHook } from '@stt-componentes/core';
import { pushArray, renderItem, calcularIdade } from '../../../util';
import { dadosExame, exameSelecionado } from '../../../signals';
import { useSignal } from '@preact/signals-react';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const Paciente = () => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { paciente } = dadosExame.value[exameSelecionado.value];
    const { 0: dadosPaciente } = paciente;
    const arrayDadosPaciente = useSignal([]);

    useSignalEffect(() => {
        let aux = [];
        let dataNascimentoFormatada = null;
        let idadePaciente = null;
        if (dadosPaciente.data_nascimento && moment(dadosPaciente.data_nascimento).isValid()) {
            dataNascimentoFormatada = moment(dadosPaciente.data_nascimento).format('DD/MM/YYYY');
            idadePaciente = calcularIdade(dadosPaciente.data_nascimento, strings.anos);
        }

        pushArray(aux, { titulo: strings.nome, conteudo: dadosPaciente.nome });
        pushArray(aux, { titulo: strings.dataNascimento, conteudo: dataNascimentoFormatada });
        pushArray(aux, { titulo: strings.idade, conteudo: idadePaciente });

        arrayDadosPaciente.value = aux;
    });

    return (
        <>
            {
                arrayDadosPaciente.value.length > 0 &&
                <>
                    <SttExpansionPanel title={strings.dadosPaciente}
                        children={
                            arrayDadosPaciente.value.map((dado, index) => renderItem(dado, index))
                        }
                    />
                </>
            }
        </>
    );
};

export default Paciente;