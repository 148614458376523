import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttExternal as SttExterno,
    SttHeading,
    SttPaper,
    SttBox,
    SttGrid,
    SttLink
} from '@stt-componentes/core';
import CampoProtocolo from './protocolo';
import LayoutAlternativoUfpa from './ufpa';

const useStyles = makeStyles(theme => ({
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2)
    },
    heading: {
        margin: theme.spacing(2)
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    link: {
        fontSize: '1rem',
        cursor: 'pointer',
        color: '#ffffff',
        textTransform: 'uppercase',
        fontFamily: "Sans-serif"
    },
    divLink: {
        marginBottom: theme.spacing(1)
    },
    spanLink: {
        color: '#93c01f',
        transform: 'rotate(180deg)',
        display: 'inline-block'
    }
}));

const TelaProtocolo = ({ protocoloUrl, ...props }) => {
    let propsCss = {
        basename: global.gConfig.basename
    };

    const classes = useStyles(propsCss);

    const retornarLayoutTelaProtocolo = () => {
        switch (global.gConfig.basename) {
            case 'ufpa':
                return <LayoutAlternativoUfpa protocoloUrl={protocoloUrl} />
            default:
                return <>
                    <SttExterno
                        instancia={{
                            logoHeader: global.gConfig.logo_header,
                            logoFooter: global.gConfig.logo_footer
                        }}
                        basename={global.gConfig.basename}
                        {...props}
                    >

                        <SttGrid container direction='column' alignItems='center'>
                            <SttGrid item sm={12} xs={12}>
                                <SttHeading
                                    variant="h1"
                                    color="primary"
                                    className={classes.heading}
                                >
                                    {global.gConfig.instancia_nome}
                                </SttHeading>
                            </SttGrid>
                            <SttGrid item sm={12} xs={12}>
                                {
                                    global.gConfig.basename === 'ufpa' &&
                                    <div className={classes.divLink}>
                                        <SttLink className={classes.link} href={'https://telessaude.chu.ufpa.br/'} underline='none'><span className={classes.spanLink}>&#10148;</span>Página inicial</SttLink>
                                    </div>
                                }
                                <SttPaper className={classes.formColumn}>
                                    <SttBox className={classes.box} letterSpacing={1}>
                                        <CampoProtocolo protocoloUrl={protocoloUrl} />
                                    </SttBox>
                                </SttPaper>
                            </SttGrid>
                        </SttGrid>
                    </SttExterno>
                </>
        }
    }
    return (
        retornarLayoutTelaProtocolo()
    );
};


export default TelaProtocolo;

