import React, { useContext } from 'react';
import { SttExpansionPanel, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from '@material-ui/core/styles';
import { renderItem, pushArray } from '../../../util.js';
import moment from 'moment';
import { dadosExame, exameSelecionado } from '../../../signals/index.js';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(() => ({
    fonteProtocolo: {
        fontFamily: '"Noto Sans"'
    },
    divBotoes: {
        marginTop: '5px'
    }
}));

const Exame = ({ BotaoImpressao, BotaoDownload }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const { exame, instituicao: [dadosInstituicao] } = dadosExame.value[exameSelecionado.value];
    const arrayDadosExame = useSignal([]);

    useSignalEffect(() => {
        let aux = [];

        pushArray(aux, { titulo: strings.dataExame, conteudo: moment(exame.data).format('DD/MM/YYYY HH:mm') });
        pushArray(aux, { titulo: strings.protocolo, conteudo: exame.protocolo_rctm, classContent: classes.fonteProtocolo });
        pushArray(aux, { titulo: strings.solicitante, conteudo: exame.nome_funcionario_requisitante });
        pushArray(aux, { titulo: strings.modalidade, conteudo: exame.descricao_modalidade });

        if (dadosInstituicao) {
            pushArray(aux, { titulo: strings.instituicao, conteudo: `${dadosInstituicao.nome} (${strings.cnes}: ${dadosInstituicao.cnes})` });
            pushArray(aux, { titulo: strings.localidade, conteudo: `${dadosInstituicao.municipio} (${dadosInstituicao.estado})` });
        }

        arrayDadosExame.value = aux;
    });

    return (
        <>
            <SttExpansionPanel title={strings.dadosExame}
                children={
                    <>
                        {
                            arrayDadosExame.value.map((dado, index) => renderItem(dado, index))
                        }
                    </>
                }
            />

            <div className={classes.divBotoes}>
                {
                    BotaoImpressao && dadosExame.value && dadosExame.value.exame && <BotaoImpressao />
                }
                {
                    BotaoDownload && dadosExame.value && dadosExame.value.exame && <BotaoDownload />
                }
            </div>
        </>
    );
};

export default Exame;