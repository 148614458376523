export const SITUACAO = {
    PACIENTE: 1,
    EXAME: 2,
    IMAGENS: 3,
    RESULTADO: 4
};

export const CONSTANTES_GERAIS = {
    TECLA_ENTER: 'Enter'
};

export const CONSTANTES_MODALIDADES = {
    DERMATOLOGIA: 'VLP',
    ELETROCARDIOGRAMA: 'ECG',
    ELETROENCEFALOGRAFIA: 'EEG',
    ESPIROMETRIA: 'ESP',
    ESTOMATOLOGIA: 'EST',
    POLISSONOGRAFIA: 'PSG',
    PATOLOGIA: 'BP',
    RADIOLOGIA_COMPUTADORIZADA: 'CR'
};

export const TIPO_ANEXO = {
    PDF: 'pdf',
    BMP: 'bmp'
};

export const MODALIDADES_TELEDIAGNOSTICO = [
    CONSTANTES_MODALIDADES.ELETROCARDIOGRAMA,
    CONSTANTES_MODALIDADES.ELETROENCEFALOGRAFIA,
    CONSTANTES_MODALIDADES.DERMATOLOGIA,
    CONSTANTES_MODALIDADES.ESPIROMETRIA,
    CONSTANTES_MODALIDADES.ESTOMATOLOGIA,
    CONSTANTES_MODALIDADES.POLISSONOGRAFIA
];

export const SIM = 'S';

export const TIPO_MODALIDADE_IMPRESSAO_RADIOLOGIA = 'CR';
export const DESCRICAO_TIPO_MODALIDADE_IMPRESSAO_RADIOLOGIA = 'Radiologia';