import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttExternal as SttExterno,
    SttHeading,
    SttPaper,
    SttBox,
    SttGrid,
    SttLink
} from '@stt-componentes/core';
import CampoProtocolo from '../protocolo';

const useStyles = makeStyles(theme => ({
    forms: {
        alignItems: 'stretch',
        flexDirection: 'row',
        display: 'flex',
        padding: '25px',
        flexWrap: 'wrap'
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#000000',
        display: 'flex',
        margin: 'auto',
        width: theme.spacing(35),
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
        background: 'linear-gradient(to bottom, #007335 0, #007335 70px, #85cde5 70px, #85cde5 100%)'
    },
    input: {
        width: '80%'
    },
    heading: {
        margin: theme.spacing(2),
        color:  '#ffffff',
        textTransform: 'uppercase',
        fontFamily: 'Sans-serif',
        textAlign: 'center'
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    wrapper: {
        paddingBottom: theme.spacing(4)
    },
    link: {
        fontSize: '1rem',
        cursor: 'pointer',
        color: '#ffffff',
        textTransform: 'uppercase',
        fontFamily: "Sans-serif"
    },
    divLink: {
        margin: 'auto',
        width: theme.spacing(40),
        marginBottom: theme.spacing(1)
    },
    spanLink: {
        color: '#93c01f',
        transform: 'rotate(180deg)',
        display: 'inline-block'
    },
    gridContainer: {
        width: '100%',
        background: 'linear-gradient(to bottom, #c41a1b 0, #c41a1b 99px, #93c01f 99px, #93c01f 100%)'
    }
}));

const LayoutAlternativoUfpa = ({ protocoloUrl, ...props }) => {
    let propsCss = {
        basename: global.gConfig.basename
    };

    const classes = useStyles(propsCss);

    return (
        <SttExterno
            instancia={{
                logoHeader: global.gConfig.logo_header,
                logoFooter: global.gConfig.logo_footer
            }}
            basename={global.gConfig.basename}
            {...props}
        >
            <SttGrid container direction='column' alignItems='center'>
                <SttGrid item sm={12} xs={12}>
                    <SttHeading
                        variant="h1"
                        color="primary"
                        className={classes.heading}
                    >
                        {global.gConfig.instancia_nome}
                    </SttHeading>
                </SttGrid>
                <SttGrid item sm={12} xs={12} className={classes.gridContainer}>
                    {
                        global.gConfig.basename === 'ufpa' &&
                        <div className={classes.divLink}>
                            <SttLink className={classes.link} href={'https://telessaude.chu.ufpa.br/'} underline='none'><span className={classes.spanLink}>&#10148;</span>Página inicial</SttLink>
                        </div>
                    }
                    <SttPaper className={classes.formColumn}>
                        <SttBox className={classes.box} letterSpacing={1}>
                            <CampoProtocolo protocoloUrl={protocoloUrl} />
                        </SttBox>
                    </SttPaper>
                </SttGrid>
            </SttGrid>
        </SttExterno>
    );
};

export default LayoutAlternativoUfpa;

