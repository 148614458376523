import React, { useContext, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttCircularProgress,
    SttTranslateHook,
    SttNotification,
    SttGrid,
    SttText
} from '@stt-componentes/core';
import axios from 'axios';
import { CONSTANTES_MODALIDADES } from '../../../constantes';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import ListaAnexos from './listaAnexos';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { dadosExame, exameSelecionado, podeExibirGaleria } from '../../../signals';

const MODALIDADES_TELEDIAGNOSTICO = [
    CONSTANTES_MODALIDADES.DERMATOLOGIA,
    CONSTANTES_MODALIDADES.ELETROCARDIOGRAMA,
    CONSTANTES_MODALIDADES.ELETROENCEFALOGRAFIA,
    CONSTANTES_MODALIDADES.ESPIROMETRIA,
    CONSTANTES_MODALIDADES.ESTOMATOLOGIA,
    CONSTANTES_MODALIDADES.POLISSONOGRAFIA
];

const useStyles = makeStyles(theme => ({
    divListaImagens: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const Imagens = () => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
    const anexosController = useRef();
    const arquivosExternos = useSignal(null);
    const ohifLoaded = useSignal(false);
    const urlOhif = useSignal('');

    useSignalEffect(() => {
        if (anexosController.current) {
            anexosController.current.abort();
        }
        anexosController.current = new AbortController();
        // Carrega anexos
        axios
            .get(`${EXAMES_API_BASE_URL}/exame/${dadosExame.value[exameSelecionado.value].exame.id}/anexo`, { signal: anexosController.current.signal })
            .then((response) => {
                arquivosExternos.value = response.data;
            })
            .catch(err => {
                console.log('err', err);
            });
        
        // Verifica cache dicom
        if (dadosExame.value[exameSelecionado.value].exame.possui_imagens) {
            axios
                .get(`${EXAMES_API_BASE_URL}/exame/${dadosExame.value[exameSelecionado.value].exame.studyinstanceuid}/dicom-cache`, { signal: anexosController.current.signal })
                .then((response) => {
                    const { data } = response;
                    urlOhif.value = data.data.url_ohif;
                })
                .catch(err => {
                    console.log(err);
                    urlOhif.value = dadosExame.value[exameSelecionado.value].exame.url_ohif;
                });
        }


        return () => {
            if (anexosController.current) {
                anexosController.current.abort();
            }
        }
    });

    useSignalEffect(() => {
        if (urlOhif.value) {
            let url = urlOhif.value.replace('<StudyInstanceUID>', dadosExame.value[exameSelecionado.value].exame.studyinstanceuid);
            if (dadosExame.value[exameSelecionado.value].exame.versao_dcm4chee === 5) {
                urlOhif.value = url.replace('<token>', dadosExame.value[exameSelecionado.value].exame.token_pacs);
            } else {
                urlOhif.value = url.replace('?access_token=<token>', '');
            }
            ohifLoaded.value = true;
        }
    });

    return (
        <>
            {
                !podeExibirGaleria.value ?
                    <SttNotification severity="info" className={classes.notification}>
                        <div className={classes.notificationContent}>
                            <span>{strings.exameSemPermissaoGaleria}</span>
                        </div>
                    </SttNotification>
                    :
                    <>
                        {
                            !MODALIDADES_TELEDIAGNOSTICO.includes(dadosExame.value[exameSelecionado.value].exame.sigla_modalidade) && dadosExame.value[exameSelecionado.value].exame.possui_imagens &&
                            <>
                                {
                                    !ohifLoaded.value ?
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12} className={classes.divLoading}>
                                            <SttCircularProgress />
                                            <SttText>{`${strings.carregandoImagens}...`}</SttText>
                                        </SttGrid>
                                    </SttGrid> :
                                    <iframe
                                        width="100%"
                                        height={window.innerHeight * 0.70}
                                        src={urlOhif.value}
                                        style={{ border: 'none' }}
                                    />
                                }
                            </>
                        }
                        {
                            MODALIDADES_TELEDIAGNOSTICO.includes(dadosExame.value[exameSelecionado.value].exame.sigla_modalidade) &&
                            <SttGaleriaImagens
                                idExame={dadosExame.value[exameSelecionado.value].exame.id}
                                strings={strings}
                                config={global.gConfig}
                                tokenTelessaude={dadosExame.value[exameSelecionado.value].exame.token_pacs}
                            />
                        }
                    </>
            }
            {
                arquivosExternos.value?.length > 0 &&
                <div className={classes.divListaImagens}>
                    <ListaAnexos anexos={arquivosExternos.value} />
                </div>
            }
        </>
    );
};

export default Imagens;